import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import { addDays, differenceInDays, isWeekend, format  } from 'date-fns';

import { LeaveStatuses } from '../../../utils/LeaveStatuses';
import { LeaveTypes } from '../../../utils/LeaveTypes';

const leaveColumns = [
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
    },
    {
        name: 'Start Date',
        selector: row => row.start_date,
        sortable: true,
    },
    {
        name: 'End Date',
        selector: row => row.end_date,
        sortable: true,
    },
    {
        name: 'Reviewer',
        selector: row => row.reviewer,
        sortable: true,
    },
    {
        name: 'Review Date',
        selector: row => row.review_date,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        width: '200px'
    },
];

let leaveData = [];

const mercantileHolidays = [
  "2024-01-15",
  "2024-01-25",
  "2024-02-23",
  "2024-03-24",
  "2024-04-12",
  "2024-04-15",
  "2024-04-23",
  "2024-05-01",
  "2024-05-23",
  "2024-05-24",
  "2024-06-21",
  "2024-08-19",
  "2024-09-16",
  "2024-09-17",
  "2024-10-17",
  "2024-11-15",
  "2024-12-25",
  "2025-01-13",
  "2025-01-14",
  "2025-02-04",
  "2025-02-12",
  "2025-03-13",
  "2025-04-14",
  "2025-05-01",
  "2025-05-12",
  "2025-05-13",
  "2025-06-10",
  "2025-07-10",
  "2025-08-08",
  "2025-09-05",
  "2025-10-06",
  "2025-11-05",
  "2025-12-04",
  "2025-12-25",
]

const ViewLeaves = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [leaveList, setLeaveList] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const holidayCount = (startDate, EndDate) => {
      let loopDate = new Date(startDate);
      let removeDaysCount = 0;
      while (loopDate <= new Date(EndDate)) {
        if (isWeekend(loopDate)) {
          removeDaysCount++;
        }

        if (mercantileHolidays.includes(format(loopDate, 'yyyy-MM-dd'))) {
          removeDaysCount++;
        }

        loopDate = addDays(loopDate,1);
      }

      return removeDaysCount;
    }

    useEffect(() => {
      let isMounted = true;
      axios.get(`/api/get-leaves`).then(res => {
        if (isMounted) {
          if (res.data.status === 200) {
            setLeaveList(res.data.leaves);

            let thisYearLeaves = [];

            // Get Aproved Leaves for this year
            res.data.leaves.forEach(leave => {
              if ((new Date(leave.start_date)).getFullYear() === new Date().getFullYear() && leave.status === 'approved') {
                thisYearLeaves.push(leave)
              }
            });

            let leavesCount = [0,0,0,0,0]
            thisYearLeaves.forEach(leave => {
              if (leave.type === 'casual') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[0] = leavesCount[0]+days+1-removeDaysCount;
              }
              if (leave.type === 'half-day') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                if (removeDaysCount === 0) {
                  leavesCount[0] = leavesCount[0]+0.5;
                }
              }
              if (leave.type === 'half-day-medical') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                if (removeDaysCount === 0) {
                  leavesCount[1] = leavesCount[1]+0.5;
                }
              }
              if (leave.type === 'sick') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[1] = leavesCount[1]+days+1-removeDaysCount;
              }
              if (leave.type === 'annual') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[2] = leavesCount[2]+days+1-removeDaysCount;
              }
              if (leave.type === 'nopay') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[4] = leavesCount[4]+days+1-removeDaysCount;
              }
              if (leave.type === 'short') {
                if ((new Date(leave.start_date)).getMonth() === new Date().getMonth()) {
                  const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                  if (removeDaysCount === 0) {
                    leavesCount[3] = leavesCount[3]+1;
                  }
                }
              }
            });

            setLeaveCount(leavesCount);
            setLoading(false);
          }
        }
      });
      return () => {
        isMounted = false
      }
    }, []);

    if (loading) {
        return <h4>Loading Leaves...</h4>
    } else {
        if (leaveList.length > 0) {
            leaveData = leaveList.map(item => {
                return ({
                    type    :   LeaveTypes.map((leaveType, idx) => item.type === leaveType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${leaveType.value}`}>{leaveType.label}</span> : ''),
                    start_date  :   item.start_date,
                    end_date    :   item.end_date,
                    reviewer    :   item.to_user && item.to_user.name,
                    review_date :   item.review_date,
                    status  :   LeaveStatuses.map((leaveStatus, idx) => item.status === leaveStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${leaveStatus.value}`}>{leaveStatus.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`view-leave/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                </div>,
                });
            });
        }
    }

    return (
        <div className='container py-5'>
          <div className="pt-5 leaves-count-container">
            <div className="leaves-count-columns">
              <div className="card text-bg-dark mb-3">
                <div className="card-header text-center"><h5>Casual Leaves</h5></div>
                <div className="card-body">
                  <h5 className={`card-title text-center ${leaveCount[0]>7 ? 'text-danger' : ''}`}>{`${leaveCount[0]} / 7`}</h5>
                </div>
              </div>
            </div>
            <div className="leaves-count-columns">
              <div className="card text-bg-dark mb-3">
                  <div className="card-header text-center"><h5>Sick Leaves</h5></div>
                  <div className="card-body">
                    <h5 className={`card-title text-center ${leaveCount[1]>7 ? 'text-danger' : ''}`}>{`${leaveCount[1]} / 7`}</h5>
                  </div>
                </div>
              </div>
            <div className="leaves-count-columns">
              <div className="card text-bg-dark mb-3">
                  <div className="card-header text-center"><h5>Annual Leaves</h5></div>
                  <div className="card-body">
                    <h5 className={`card-title text-center ${leaveCount[2]>14 ? 'text-danger' : ''}`}>{`${leaveCount[2]} / 14`}</h5>
                  </div>
                </div>
              </div>
            <div className="leaves-count-columns">
              <div className="card text-bg-dark mb-3">
                <div className="card-header text-center"><h5>Short Leaves</h5></div>
                <div className="card-body">
                  <h5 className={`card-title text-center ${leaveCount[3]>2 ? 'text-danger' : ''}`}>{`${leaveCount[3]} / 2`}</h5>
                </div>
              </div>
            </div>
            <div className="leaves-count-columns">
              <div className="card text-bg-dark mb-3">
                <div className="card-header text-center"><h5>No Pay Leaves</h5></div>
                <div className="card-body">
                  <h5 className={`card-title text-center`}>{`${leaveCount[4]}`}</h5>
                </div>
              </div>
            </div>
          </div>
              
            <div className='card'>
              <div className='card-header'>
                <h4>Leave List
                  <Link to="/add-leave" className='btn btn-primary btn-sm float-end'>Request a Leave</Link>
                </h4>
              </div>
              <div className='card-body'>
                <DataTable
                  columns={leaveColumns}
                  data={leaveData}
                  pagination
                />
              </div>
            </div>
        </div>
    );
}

export default ViewLeaves;